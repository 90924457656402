import { ForgotPasswordWrapper, StepWrapper } from './styled';
import { DeleteAcountNotication } from 'components/DeleteAccount/DeleteAcountNotication';
import { DeleteAcountConfirm } from 'components/DeleteAccount/DeleteAcountConfirm';

export const DeleteAccount = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');

  return (
    <ForgotPasswordWrapper>
      <StepWrapper>
        {token ? (
          <DeleteAcountConfirm token={token}></DeleteAcountConfirm>
        ) : (
          <DeleteAcountNotication></DeleteAcountNotication>
        )}
      </StepWrapper>
    </ForgotPasswordWrapper>
  );
};
