import { Form, Spin, Tooltip } from 'antd';
import { ReactComponent as EyeOffIcon } from 'assets/icons/eye-off.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/eye-show.svg';
import { ReactComponent as MailIcon } from 'assets/icons/mail-icon.svg';
import { ReactComponent as PasswordIcon } from 'assets/icons/password.svg';
import carvIcon from 'assets/images/carv_id.png';
import gradient from 'assets/images/register/gradient.svg';
import metaMaskIcon from 'assets/logo-metamask.png';
import geminiIcon from 'assets/vectors/login-gemini.svg';
import { Input } from 'components/Elements/Input';
import { GaActions, GaCategories, GaLabels } from 'constants/enum/GaEvent';
import { PitRouter } from 'constants/routers';
import useEnableNotification from 'hooks/notification/useEnableNotification';
import { useCheckConnectedWallet } from 'hooks/player/useCheckConnectedWallet';
import useOneSignal from 'hooks/useOneSignal';
import { useEffect, useMemo, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IRegister, postRegister, verifyRecaptcha } from 'services/api';
import { everflowConversion } from 'utils/everflow';
import { LocalhostStorage } from '../../utils/sessionStorage';
import {
  BigaLogoWrapper,
  CheckboxWrapper,
  CloseIconWrapper,
  CustomFormItem,
  FormWrapper,
  GradientTop,
  ReCAPTCHAWrapper,
  RegisterConnectButton,
  RegisterConnectButtonsWrapper,
  RegisterConnectText,
  RegisterConnectedInfo,
  RegisterFirstStepFooter,
  RegisterStepButton,
  RegisterStepButtonWrapper,
  RegisterStepDescription,
  RegisterStepLink,
  RegisterStepTitle,
  Wrapper,
} from './styled';
import useWagmiConnectors from 'hooks/useWagmiConnectors';
import { useAccount, useSignMessage } from 'wagmi';

interface IProps {
  onContinue: () => void;
  setWaitToVerifyEmailAddress: (value: string) => void;
  setOtpExpiredAt: React.Dispatch<React.SetStateAction<number>>;
}

export const RegisterForm = ({
  onContinue,
  setWaitToVerifyEmailAddress,
  setOtpExpiredAt,
}: IProps) => {
  const termsLink = `${process.env.REACT_APP_HOST}/termsandconditions.pdf`;
  const policyLink = `${process.env.REACT_APP_HOST}/privacy-policy.pdf`;
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  let bot: boolean | undefined;
  let telegramId: number | undefined;
  let chatId: number | undefined;
  const botParam: string = searchParams.get('bot');
  const telegramIdParam: string = searchParams.get('telegramId');
  const chatIdParam: string = searchParams.get('chatId');
  if (botParam) {
    bot = botParam === 'true';
  }
  if (telegramIdParam) {
    telegramId = parseInt(telegramIdParam);
  }
  if (chatIdParam) {
    telegramId = parseInt(chatIdParam);
  }

  // Don't cache if coming from bot
  if (bot) {
    LocalhostStorage.remove('accessToken');
    LocalhostStorage.remove('refreshToken');
    LocalhostStorage.remove('tokenType');
  }

  const [inputPass, setInputPass] = useState<string>();
  const [inputConfirmPass, setInputConfirmPass] = useState<string>();
  const [emailAddress, setEmailAddress] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);
  const [metaMaskLoading, setMetaMaskLoading] = useState<boolean>(false);
  const [metaMaskClicked, setMetaMaskClicked] = useState<boolean>(false);
  const { initOneSignal } = useOneSignal();
  const isEnableNotification = useEnableNotification();

  const [typeOfConnector, setTypeOfConnector] = useState<string>();
  const { address: account } = useAccount();
  const { onConnectWallet, onDisconnect } = useWagmiConnectors();

  const [checkWalletParams, setCheckWalletParams] = useState<{
    walletAddress?: string;
  }>({});
  const {
    isConnected,
    loading: checkWalletLoading,
    setIsConnected,
  } = useCheckConnectedWallet(checkWalletParams);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const { signMessage: signMessageWagmi, reset } = useSignMessage();

  useEffect(() => {
    setCheckWalletParams({
      walletAddress: account,
    });
  }, [account]);

  useEffect(() => {
    (async () => {
      await everflowConversion();
    })();
  }, []);

  let code;

  if (window.location.pathname.split('/').pop() !== 'register') {
    code = window.location.pathname.split('/').pop();
  }

  const onSignMessageSuccess = async (data, variables) => {
    const registerData = {
      walletAddress: account,
      telegramId: telegramId,
      chatId: chatId,
      emailAddress,
      password: inputPass,
      signature: account ? data : undefined,
      message: account ? String(variables?.message) : undefined,
      affiliateCode: code || undefined,
      notificationType: [],
    };
    await onRegister(registerData);
  };

  const onRegister = async (data: IRegister) => {
    const register = await postRegister(data);
    if (register?.success && register?.payload?.data.otpExpiredAt) {
      setWaitToVerifyEmailAddress(emailAddress);
      setOtpExpiredAt(register?.payload?.data.otpExpiredAt);
      onContinue();
    } else {
      toast.error(register?.message || 'Registration failed');
      recaptchaRef.current.reset();
    }
    setLoading(false);
  };

  const handleSubmit = async () => {
    ReactGA.event({
      category: GaCategories.SIGNUP,
      action: GaActions.ACTION_SIGNUP_FORM,
      label: GaLabels.LABEL_SIGNUP_FORM,
    });

    const captchaValue = recaptchaRef.current.getValue();
    if (!captchaValue) {
      toast.error('Please verify the reCAPTCHA!');
    } else {
      setLoading(true);
      const resVerifyCaptcha = await verifyRecaptcha(captchaValue);
      if (
        resVerifyCaptcha?.success &&
        resVerifyCaptcha?.payload?.data?.success
      ) {
        if (!account) {
          const data = {
            telegramId: telegramId,
            chatId: chatId,
            emailAddress,
            password: inputPass,
            affiliateCode: code || undefined,
            notificationType: [],
          };
          onRegister(data);
          return;
        }
        if (account && typeOfConnector && !isConnected && !checkWalletLoading) {
          if (typeOfConnector === 'Metamask') {
            await signMessageWagmi(
              {
                account: account,
                message: `If you click the 'sign' button, you agree to authorize us to make this transaction.
            Your Wallet Address: ${account}
            Timestamp: ${Date.now()}
           `,
              },
              {
                onSuccess: onSignMessageSuccess,
                onError: () => {
                  onDisconnect();
                  recaptchaRef.current.reset();
                  toast.error('User denied message signature.');
                  setLoading(false);
                  reset();
                  return;
                },
              },
            );
          } else {
            setLoading(false);
            return;
          }
        }
      } else {
        toast.error(
          resVerifyCaptcha?.message || 'Please verify the reCAPTCHA!',
        );
        setLoading(false);
      }
    }
  };

  const carvSingUp = () => {
    const currentTimestamp = Date.now();
    const carvAuthUrl = `https://auth.carv.io/auth/authorize?client_id=${process.env.REACT_APP_CARV_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_CARV_REDIRECT_URI}&response_type=code&scope=${process.env.REACT_APP_CARV_SCOPE}&state=${currentTimestamp}`;
    window.location.href = carvAuthUrl;
  };

  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);

  const isFormValid = useMemo(() => {
    const isEmailValid = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(emailAddress);
    const isPasswordValid =
      inputPass &&
      inputPass.length >= 8 &&
      inputPass.length <= 30 &&
      /\d/.test(inputPass) &&
      /[a-zA-Z]/.test(inputPass) &&
      /[!@#$%^&*()+=_-]/.test(inputPass) &&
      !/[\s<>?,."|~`';:{}[\]/\\]/.test(inputPass);
    const isConfirmPasswordValid = inputPass === inputConfirmPass;
    return (
      isEmailValid && isPasswordValid && termsAccepted && isConfirmPasswordValid
    );
  }, [emailAddress, inputPass, termsAccepted, inputConfirmPass]);

  const handleMetaMask = async () => {
    setMetaMaskLoading(true);
    setMetaMaskClicked(true);
    setTypeOfConnector('Metamask');
    await onConnectWallet({
      onError: () => {
        setMetaMaskLoading(false);
      },
    });
  };

  useEffect(() => {
    if (metaMaskClicked && !!account) {
      setMetaMaskLoading(false);
    }
  }, [metaMaskClicked, account]);

  useEffect(() => {
    if (
      metaMaskClicked &&
      typeOfConnector &&
      account &&
      !checkWalletLoading &&
      isConnected
    ) {
      toast.error(
        'The wallet is connecting to another player. Please switch to other wallets',
      );
      setTypeOfConnector('');
      onDisconnect();
      setIsConnected(undefined);
      setMetaMaskLoading(false);
    }
  }, [
    account,
    checkWalletLoading,
    isConnected,
    typeOfConnector,
    metaMaskClicked,
  ]);

  useEffect(() => {
    if (account) {
      handleMetaMask();
    }
  }, []);

  return (
    <Wrapper>
      <BigaLogoWrapper onClick={() => navigate(PitRouter.HOME)} />
      <RegisterStepTitle>Registration</RegisterStepTitle>
      <RegisterFirstStepFooter>
        <RegisterStepDescription>
          Already have an account?
        </RegisterStepDescription>
        <RegisterStepLink
          disabled={loading}
          onClick={() => {
            if (bot) {
              navigate(
                `${PitRouter.LOGIN}?bot=true&telegramId=${telegramId}&chatId=${chatId}`,
              );
            } else {
              navigate(PitRouter.LOGIN);
            }
          }}
        >
          Sign In!
        </RegisterStepLink>
      </RegisterFirstStepFooter>

      <FormWrapper>
        <Form
          className="register-form"
          onFinish={handleSubmit}
          autoComplete="off"
          disabled={loading || checkWalletLoading}
        >
          <CustomFormItem
            name="email"
            rules={[
              {
                pattern: new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$'),
                message: 'Invalid email',
              },
            ]}
          >
            <Input
              prefix={<MailIcon />}
              onChange={e => setEmailAddress(e.target.value)}
              required
              placeholder="Email"
              onKeyDown={e => e.keyCode === 32 && e.preventDefault()}
            />
          </CustomFormItem>
          <Form.Item
            name="password"
            rules={[
              {
                min: 8,
                message: 'Password must be minimum 8 characters.',
              },
              {
                max: 30,
                message: 'Password must be maximum 30 characters.',
              },
              {
                validator: (_, value, callback) => {
                  if (value && !new RegExp('[0-9]').test(value)) {
                    callback('Password must contains at least one number.');
                  } else {
                    callback();
                  }
                },
              },
              {
                validator: (_, value, callback) => {
                  if (value && !new RegExp('[a-zA-Z]').test(value)) {
                    callback('Password must contains at least one alphabet.');
                  } else {
                    callback();
                  }
                },
              },
              {
                validator: (_, value, callback) => {
                  if (value && !new RegExp('[!@#$%^&*()+=_-]').test(value)) {
                    callback(
                      'Password must contain at least one special character \n from the following: !@#$%^&*()+=_-',
                    );
                  } else {
                    callback();
                  }
                },
              },
              {
                validator: (_, value, callback) => {
                  if (value && !/^[^<>?,."|~`';:{}[\]/\\]+$/.test(value)) {
                    callback(
                      'Password must not contain special characters \n from the following : <,>.?/|\\~`:;"\'{[]} ',
                    );
                  } else {
                    callback();
                  }
                },
              },
              {
                validator: (_, value, callback) => {
                  if (value && new RegExp('\\s+').test(value)) {
                    callback('Password must not contain any white space.');
                  } else {
                    callback();
                  }
                },
              },
            ]}
            validateFirst
          >
            <Input
              onChange={e => setInputPass(e.target.value)}
              type={passwordVisible ? 'text' : 'password'}
              placeholder="Password"
              prefix={<PasswordIcon />}
              required
              suffix={
                passwordVisible ? (
                  <EyeIcon
                    onClick={() => setPasswordVisible(!passwordVisible)}
                    className="fill-white"
                  />
                ) : (
                  <EyeOffIcon
                    onClick={() => setPasswordVisible(!passwordVisible)}
                  />
                )
              }
            />
          </Form.Item>
          <Form.Item
            className="mb-2"
            name="re-pass"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Password doesn’t match'));
                },
              }),
            ]}
            dependencies={['password']}
          >
            <Input
              onChange={e => setInputConfirmPass(e.target.value)}
              prefix={<PasswordIcon />}
              type={confirmPasswordVisible ? 'text' : 'password'}
              placeholder="Confirm password"
              required
              suffix={
                confirmPasswordVisible ? (
                  <EyeIcon
                    onClick={() =>
                      setConfirmPasswordVisible(!confirmPasswordVisible)
                    }
                    className="fill-white"
                  />
                ) : (
                  <EyeOffIcon
                    onClick={() =>
                      setConfirmPasswordVisible(!confirmPasswordVisible)
                    }
                  />
                )
              }
            />
          </Form.Item>
          <ReCAPTCHAWrapper>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              className="g-recaptcha"
            />
          </ReCAPTCHAWrapper>
          <CheckboxWrapper
            value={termsAccepted}
            onChange={() => setTermsAccepted(!termsAccepted)}
          >
            I am over 18 years old and I accept the{' '}
            <a href={termsLink} target="_blank" rel="noreferrer">
              Terms and Conditions,
            </a>{' '}
            and our{' '}
            <a href={policyLink} target="_blank" rel="noreferrer">
              Privacy Policy.
            </a>
          </CheckboxWrapper>

          <RegisterStepButtonWrapper>
            <RegisterStepButton
              htmlType="submit"
              loading={loading}
              disabled={!isFormValid}
            >
              Register
            </RegisterStepButton>
          </RegisterStepButtonWrapper>
        </Form>
      </FormWrapper>

      <RegisterConnectText>Connect Wallet (optional)</RegisterConnectText>

      <RegisterConnectButtonsWrapper>
        <RegisterConnectButton disabled={loading} onClick={() => carvSingUp()}>
          <img src={carvIcon} alt="carv-icon" />
          <span>CARV ID</span>
        </RegisterConnectButton>
        {account &&
        typeOfConnector &&
        !checkWalletLoading &&
        isConnected === false ? (
          <>
            <RegisterConnectedInfo>
              <img
                src={typeOfConnector === 'Metamask' ? metaMaskIcon : geminiIcon}
                alt="metamask-icon"
              />
              <div>{`${account.substring(0, 6)}...${account.substring(
                38,
              )}`}</div>
            </RegisterConnectedInfo>
            <RegisterConnectButton onClick={onDisconnect}>
              <span>Disconnect</span>
            </RegisterConnectButton>
          </>
        ) : (
          <>
            <RegisterConnectButton
              className="metamask-btn"
              disabled={loading}
              onClick={handleMetaMask}
            >
              <Spin spinning={metaMaskLoading}></Spin>
              <img src={metaMaskIcon} alt="metamask-icon" />
              <span>METAMASK</span>
            </RegisterConnectButton>
            <Tooltip title="Coming soon">
              <RegisterConnectButton disabled>
                <img src={geminiIcon} alt="gemini-icon" />
                <span>GEMINI</span>
              </RegisterConnectButton>
            </Tooltip>
          </>
        )}
      </RegisterConnectButtonsWrapper>
      <GradientTop src={gradient} />
      <CloseIconWrapper onClick={() => navigate('..')} />
    </Wrapper>
  );
};
