import { Button } from 'antd';
import { media } from 'constants/enum/breakpoints';
import styled from 'styled-components';

export const Text = styled.div`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;
`;

export const Wrapper = styled.div`
  text-align: center;
  background: transparent;
  padding: 2.5rem;

  max-width: 100%;

  margin-bottom: 1rem;
`;

export const ModalVerifyOtpWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const RegisterVerifyCodeWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
`;

export const RegisterVerifyCodeStepHeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.625rem;
  align-items: center;
  gap: 1.5rem;
`;

export const RegisterStepTitle = styled.div`
  color: rgb(255, 255, 255);
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;

  max-width: 28rem;
`;

export const RegisterStepDescription = styled.div`
  color: rgb(161, 161, 165);
  text-align: center;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  text-align: center;
`;

export const RegisterVerifyCodeBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
`;

export const RegisterVerifyCodeInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.375rem;
`;

export const RegisterVerifyCodeInput = styled.input`
  border: 1px solid rgba(30, 30, 30, 0.94);
  background: rgb(17, 17, 21);
  padding: 0.625rem 1rem;
  text-align: center;
  width: 54px;
  aspect-ratio: 1 / 1;
  height: 54px;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: var(--biga-blue);
  border-radius: 8px;
  outline: none;

  ${media.sm} {
    width: 2.5rem;
    font-size: 1rem;
    padding: 0.25rem 0.5rem;
  }

  @media (max-width: 348px) {
    width: 1.5rem;
    font-size: 0.5rem;
  }

  outline: none;
`;

export const TextErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  & > img {
    cursor: pointer;
  }
`;

export const TextError = styled.p`
  color: #ff3b3b;
  /* text-align: center; */
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
`;

export const CenterTextError = styled(TextError)`
  text-align: center;
  white-space: pre-wrap;
`;

export const BackText = styled.div`
  white-space: pre-wrap;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: rgb(161, 161, 165);
`;

export const RegisterStepBlueLink = styled(Button)`
  color: #fff;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 12px 10px;
  background: linear-gradient(90deg, #0038ff -54.32%, #00aafd 100%), #fff;
  box-shadow: 0px 0px 1px 0px rgba(0, 187, 255, 0.24),
    0px 2px 4px -1px rgba(10, 70, 82, 0.12),
    0px 16px 24px 0px rgba(0, 187, 255, 0.24),
    0px 8px 8px -4px rgba(0, 187, 255, 0.12);
  border-radius: 50px;
  flex: 1;
  border: none;
  height: 58px;

  ${media.sm} {
    font-size: 16px;
  }
`;

export const RegisterVerifyCodeStepTimer = styled.div`
  color: #ff3b3b;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const BackgroundGradient = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  inset: 0;
  width: 100%;
  height: 100%;
  border-radius: 24px;
  z-index: 100;
`;
