import congratsPoster from 'assets/vectors/register-congrats.svg';
import { SplashVideoFullScreen } from 'components/AccessControl/styled';
import { GaActions, GaCategories, GaLabels } from 'constants/enum/GaEvent';
import { PitRouter } from 'constants/routers';
import { useState } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import gradient from 'assets/images/register/gradient.svg';
import { everflowClick } from 'utils/everflow';

import {
  BigaLogoWrapper,
  CloseIconWrapper,
  CongratulationText,
  GradientTop,
  RegisterCongratulationHeadingWrapper,
  RegisterCongratulationWrapper,
  RegisterStepButton,
  RegisterStepDescription,
} from './styled';

export const RegisterCongratulations = ({
  setIsHideForm,
}: {
  setIsHideForm: (value: boolean) => void;
}) => {
  const navigate = useNavigate();
  const [showVideo, setShowVideo] = useState<boolean>(false);

  const handleEnterArcadeClick = () => {
    (async () => {
      await everflowClick();
    })();
    ReactGA.event({
      category: GaCategories.SIGNUP,
      action: GaActions.ACTION_SIGNUP_COMPLETED,
      label: GaLabels.LABEL_SIGNUP_COMPLETED,
    });
    setIsHideForm(true);
    setShowVideo(true);
  };

  return !showVideo ? (
    <RegisterCongratulationWrapper>
      <BigaLogoWrapper onClick={() => navigate(PitRouter.HOME)} />
      <CongratulationText />
      <RegisterCongratulationHeadingWrapper>
        <RegisterStepDescription>
          Your signup is completed! Thank you for joining us.
        </RegisterStepDescription>
      </RegisterCongratulationHeadingWrapper>

      <RegisterStepButton onClick={handleEnterArcadeClick}>
        Enter Arcade
      </RegisterStepButton>
      <GradientTop src={gradient} />
      <CloseIconWrapper onClick={() => navigate('..')} />
    </RegisterCongratulationWrapper>
  ) : (
    <SplashVideoFullScreen
      onEnded={() => {
        navigate(PitRouter.GAME_LIST, {
          state: { isConversion: true },
        });
        setShowVideo(false);
      }}
      className="splash-video-full-screen"
    />
  );
};
