import { WarningTwoTone } from '@ant-design/icons';
import { ApplicationModal } from 'redux/slices/application';
import { useModalIsOpen, useToggleModal } from 'redux/slices/application/hook';
import {
  ConfirmDeleteButtonWrapper,
  ConfirmDeleteNoButton,
  ConfirmDeleteYesButton,
  ModalConfirmDeleteTitle,
  ModalConfirmDeleteWrapper,
  ModalDeleteAccountWrapper,
  ModalDeleteContainer,
  ModalWrapper,
  NoteWrapper,
  WarningWrapper,
} from './styled';
import { useState } from 'react';
import { sendMailDeleteAccount } from 'services/api';
import { PitRouter } from 'constants/routers';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export const ConfirmDelete = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const open = useModalIsOpen(ApplicationModal.PROFILE_CONFIRM_DELETE_ACCOUNT);
  const toggleModal = useToggleModal(
    ApplicationModal.PROFILE_CONFIRM_DELETE_ACCOUNT,
  );
  const handleCancel = () => {
    toggleModal();
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const res = await sendMailDeleteAccount();
      if (res?.success) {
        navigate(PitRouter.DELETE_ACCOUNT);
      } else {
        toast.error(res?.message || 'An error occurred');
      }
    } catch (error) {
      toast.error(error.message || 'An error occurred');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalWrapper
      open={open}
      onCancel={handleCancel}
      maskClosable={true}
      footer={null}
      width="620px"
    >
      <ModalDeleteAccountWrapper>
        <ModalDeleteContainer>
          <ModalConfirmDeleteWrapper>
            <ModalConfirmDeleteTitle>
              Delete Profile Confirmation
            </ModalConfirmDeleteTitle>

            <WarningWrapper>
              <WarningTwoTone twoToneColor="#fcd00d" />
              Warning!
              <WarningTwoTone twoToneColor="#fcd00d" />
            </WarningWrapper>

            <NoteWrapper>
              <li>
                Deleting your profile is permanent and cannot be reactivated.
              </li>
              <li>
                Once deleted, you will be disqualified from any future earnings
                or bonuses from our arcade.
              </li>
              <li>
                Please note: Your scores will remain visible. This is to ensure
                accurate recording based on total plays.
              </li>
            </NoteWrapper>

            <ConfirmDeleteButtonWrapper>
              <ConfirmDeleteYesButton onClick={handleSubmit} loading={loading}>
                Yes, Delete My Profile
              </ConfirmDeleteYesButton>
              <ConfirmDeleteNoButton onClick={handleCancel}>
                No, Go Back
              </ConfirmDeleteNoButton>
            </ConfirmDeleteButtonWrapper>
          </ModalConfirmDeleteWrapper>
        </ModalDeleteContainer>
      </ModalDeleteAccountWrapper>
    </ModalWrapper>
  );
};
