import { MailOutlined } from '@ant-design/icons';
import { ReactComponent as BackIcon } from 'assets/icons/close-icon.svg';
import { PitRouter } from 'constants/routers';
import { useNavigate } from 'react-router-dom';
import gradient from 'assets/images/register/gradient.svg';

import {
  BigaLogoWrapper,
  ForgotBackButton,
  ForgotSuccessContainer,
  GradientTop,
  SuccessDetailText,
  SuccessText,
  Wrapper,
} from './styled';

export const DeleteAcountNotication = () => {
  const navigate = useNavigate();

  const onBackLogin = () => {
    navigate(PitRouter.PROFILE);
  };

  return (
    <Wrapper>
      <ForgotBackButton onClick={onBackLogin}>
        <BackIcon />
      </ForgotBackButton>
      <BigaLogoWrapper onClick={() => navigate(PitRouter.HOME)} />

      <ForgotSuccessContainer>
        <MailOutlined className="email-icon" />
        <SuccessText>Check your mail</SuccessText>
        <SuccessDetailText>
          We have sent delete account instructions to your email.
        </SuccessDetailText>
      </ForgotSuccessContainer>

      <GradientTop src={gradient} />
    </Wrapper>
  );
};
