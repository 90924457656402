import { Button, Form } from 'antd';
import { Input } from 'components/Elements/Input';
import { media } from 'constants/enum/breakpoints';
import styled from 'styled-components';
import { ReactComponent as BigaLogo } from 'assets/images/BIGA-logo.svg';

export const Wrapper = styled.div`
  position: relative;
  text-align: center;
  background: linear-gradient(to bottom, #161923, #0f0f13);
  padding: 2.5rem;
  width: 28rem;
  border-radius: 24px;
  overflow: hidden;

  ${media.md2} {
    background: none;
  }

  ${media.sm} {
    width: 100%;
    padding: 24px;
  }
`;
export const WrapperImage = styled.img`
  width: 50px;
  height: 50px;
  margin-top: 3rem;
  margin-bottom: 1rem;
`;

export const SuccessWrapper = styled.div``;

export const BigaLogoWrapper = styled(BigaLogo)`
  margin-bottom: 30px;
  margin-top: 10px;
  cursor: pointer;
`;

export const ForgotStepButtonWrapper = styled.div`
  margin-top: 0.5rem;
`;
export const ForgotSuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 24px;
  padding: 20px 24px 48px;

  & > .email-icon {
    color: #fff;
    scale: 3.5;
  }
`;

export const SuccessContainer = styled(ForgotSuccessContainer)`
  padding: 0px 24px 48px;
`;

export const ForgotBackButton = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: right;

  color: #fff;
  text-align: center;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  cursor: pointer;

  svg path {
    fill: #fff;
  }

  ${media.md2} {
    display: none;
  }
`;

export const TextError = styled.p`
  color: #ff3b3b;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
`;

export const TextErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  & > img {
    cursor: pointer;
  }
`;

export const CenterTextError = styled(TextError)`
  text-align: center;
  white-space: pre-wrap;
`;

export const BackText = styled.div`
  white-space: pre-wrap;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 30px;

  & > .button-link-wrapper {
    flex: 2;
    display: flex;
    /* flex-wrap: wrap; */
  }

  & > .button-link-wrapper:first-of-type {
    justify-content: flex-end;
  }

  & > .button-link-wrapper:last-of-type {
    justify-content: flex-start;
  }

  & > .button-link-wrapper:first-of-type:after,
  & > .button-link-wrapper:last-of-type:before {
    content: '';
    display: inline-block;
  }
`;

export const Line = styled.div`
  width: 1px;
  height: 16px;
  background-color: var(--biga-blue);
`;

export const SuccessText = styled.span`
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  text-align: center;
`;

export const SuccessDetailText = styled.span`
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
`;

export const GradientTop = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  pointer-events: none;

  ${media.md2} {
    display: none;
  }
`;

export const ConfirmDeleteYesButton = styled(Button)`
  font-size: 20px;
  border-radius: 50px;
  font-weight: 700;
  background: linear-gradient(90deg, #ff1900 -54.32%, #e50000 100%), #fff;
  box-shadow: 0px 0px 1px 0px rgba(255, 0, 0, 0.24),
    0px 2px 4px -1px rgba(139, 0, 0, 0.12),
    0px 16px 24px 0px rgba(255, 0, 0, 0.24),
    0px 8px 8px -4px rgba(139, 0, 0, 0.12);
  border: none;
  color: #fff;
  display: flex;
  height: 58px;
  padding: 12px 22px;
  justify-content: center;
  align-items: center;
`;
