import { PitRouter } from 'constants/routers';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import gradient from 'assets/images/register/gradient.svg';
import { deletePlayerAccount } from 'services/api';
import {
  BigaLogoWrapper,
  GradientTop,
  SuccessText,
  Wrapper,
  SuccessDetailText,
  ConfirmDeleteYesButton,
  SuccessContainer,
} from './styled';
import { useLogOut } from 'hooks/useLogOut';
import errorIcon from 'assets/images/deleteAccount/red-error-icon.png';
import { toast } from 'react-toastify';

interface IResetPasswordFormProps {
  token: string;
}

export const DeleteAcountConfirm = (props: IResetPasswordFormProps) => {
  const navigate = useNavigate();
  const { token } = props;
  const logOut = useLogOut();
  const [loading, setLoading] = useState<boolean>(false);

  const deleteAccount = async () => {
    try {
      setLoading(true);
      const res = await deletePlayerAccount({ token });
      await new Promise(res => setTimeout(res, 3000));
      if (res?.success) {
        logOut();
      } else {
        toast.error(res?.message || 'An error occurred');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      <BigaLogoWrapper onClick={() => navigate(PitRouter.HOME)} />
      <SuccessContainer>
        <img src={errorIcon} width={60}></img>
        <SuccessText>Delete Account</SuccessText>
        <SuccessDetailText>
          Are you sure you want to delete the account?
        </SuccessDetailText>
        <ConfirmDeleteYesButton onClick={deleteAccount} loading={loading}>
          Delete permanently
        </ConfirmDeleteYesButton>
      </SuccessContainer>
      <GradientTop src={gradient} />
    </Wrapper>
  );
};
