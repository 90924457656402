import { Spin } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { ApplicationModal } from 'redux/slices/application';
import { useModalIsOpen, useToggleModal } from 'redux/slices/application/hook';
import { changePassword, passwordVerifyOtp } from 'services/api';

import {
  CenterTextError,
  ModalVerifyOtpWrapper,
  RegisterStepBlueLink,
  RegisterStepDescription,
  RegisterStepTitle,
  RegisterVerifyCodeBodyWrapper,
  RegisterVerifyCodeInput,
  RegisterVerifyCodeInputWrapper,
  RegisterVerifyCodeStepHeadingWrapper,
  RegisterVerifyCodeStepTimer,
  RegisterVerifyCodeWrapper,
  TextErrorWrapper,
} from './styled';
import { UpdateInformationModal } from '../UpdateInformationModal';

const RE_DIGIT = new RegExp(/^\d+$/);

interface IProps {
  onSuccess: () => void;
  oldPassword: string;
  newPassword: string;
  otpExpiredAt: number;
}

const OTP_LENGTH = 6;

export const VerifyOTPPassword = ({
  oldPassword,
  newPassword,
  onSuccess,
  otpExpiredAt,
}: IProps) => {
  const open = useModalIsOpen(ApplicationModal.PASSWORD_VERIFY_OTP);
  const toggleModal = useToggleModal(ApplicationModal.PASSWORD_VERIFY_OTP);
  const handleCancel = () => {
    toggleModal();
  };

  useEffect(() => {
    if (open) {
      setCountDownTime(otpExpiredAt * 1000);
    }
  }, [open]);

  const [countDownTime, setCountDownTime] = useState<number>(
    otpExpiredAt * 1000,
  );
  const [countDown, setCountDown] = useState<number>(
    countDownTime - Date.now(),
  );

  useEffect(() => {
    const timerInternal = setInterval(() => {
      if (countDownTime > Date.now()) {
        setCountDown(countDownTime - Date.now());
      } else {
        setCountDown(0);
        clearInterval(timerInternal);
      }
    }, 1000);

    return () => {
      clearInterval(timerInternal);
    };
  }, [countDownTime]);

  const minutes = useMemo(
    () => Math.floor(countDown / 60 / 1000).toString(),
    [countDown],
  );
  const seconds = useMemo(
    () =>
      Math.ceil((countDown / 1000) % 60)
        .toString()
        .padStart(2, '0'),
    [countDown],
  );

  const [value, setValue] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const valueItems = useMemo(() => {
    const valueArray = value.split('');
    const items: Array<string> = [];

    for (let i = 0; i < OTP_LENGTH; i++) {
      const char = valueArray[i];

      if (RE_DIGIT.test(char)) {
        items.push(char);
      } else {
        items.push('');
      }
    }

    return items;
  }, [value]);

  const focusToNextInput = (target: HTMLElement) => {
    const nextElementSibling =
      target.nextElementSibling as HTMLInputElement | null;

    if (nextElementSibling) {
      nextElementSibling.focus();
    }
  };
  const focusToPrevInput = (target: HTMLElement) => {
    const previousElementSibling =
      target.previousElementSibling as HTMLInputElement | null;

    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  };
  const inputOnChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number,
  ) => {
    setErrorMsg('');
    const target = e.target;
    let targetValue = target.value.trim();
    const isTargetValueDigit = RE_DIGIT.test(targetValue);
    console.log({ isTargetValueDigit });

    if (!isTargetValueDigit && targetValue !== '') {
      return;
    }

    const nextInputEl = target.nextElementSibling as HTMLInputElement | null;

    // only delete digit if next input element has no value
    if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== '') {
      return;
    }

    targetValue = isTargetValueDigit ? targetValue : ' ';

    const targetValueLength = targetValue.length;

    if (targetValueLength === 1) {
      const newValue =
        value.substring(0, idx) + targetValue + value.substring(idx + 1);

      setValue(newValue);
      if (newValue.length === OTP_LENGTH) {
        setLoading(true);
        const data = {
          oldPassword,
          newPassword,
          otp: newValue,
        };
        const res = await passwordVerifyOtp(data);
        if (res?.success) {
          setValue('');
          onSuccess();
        } else {
          setValue('');
          setErrorMsg(res?.message || 'Invalid OTP. Please try again.');
        }
        setLoading(false);
      }

      if (!isTargetValueDigit) {
        return;
      }

      focusToNextInput(target);
    } else if (targetValueLength === OTP_LENGTH) {
      setValue(targetValue);
      if (targetValue.length === OTP_LENGTH) {
        setLoading(true);
        const data = {
          oldPassword,
          newPassword,
          otp: targetValue,
        };
        const res = await passwordVerifyOtp(data);
        if (res?.success) {
          setValue('');
          onSuccess();
        } else {
          setValue('');
          setErrorMsg(res?.message || 'Invalid OTP. Please try again.');
        }
        setLoading(false);
      }

      target.blur();
    }
  };

  const inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;
    const target = e.target as HTMLInputElement;

    if (key === 'ArrowRight' || key === 'ArrowDown') {
      e.preventDefault();
      return focusToNextInput(target);
    }

    if (key === 'ArrowLeft' || key === 'ArrowUp') {
      e.preventDefault();
      return focusToPrevInput(target);
    }

    const targetValue = target.value;

    // keep the selection range position
    // if the same digit was typed
    target.setSelectionRange(0, targetValue.length);

    if (e.key !== 'Backspace' || targetValue !== '') {
      return;
    }

    focusToPrevInput(target);
  };
  const inputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { target } = e;

    // keep focusing back until previous input
    // element has value
    const prevInputEl =
      target.previousElementSibling as HTMLInputElement | null;

    if (prevInputEl && prevInputEl.value === '') {
      return prevInputEl.focus();
    }

    target.setSelectionRange(0, target.value.length);
  };

  const handleResendClick = async () => {
    const res = await changePassword({
      oldPassword,
      newPassword,
    });

    if (res?.success && res?.payload?.data.otpExpiredAt) {
      setValue('');
      setErrorMsg('');
      setCountDownTime(res?.payload?.data.otpExpiredAt * 1000);
      toast.success(res?.message || 'OTP sent successfully!');
    } else {
      toast.error(res?.message || 'Failed to resend OTP. Please try again.');
    }
  };

  const errorText = 'Your code is incorrect, please try again.';

  return (
    <UpdateInformationModal isOpen={open} onCancel={handleCancel}>
      <ModalVerifyOtpWrapper>
        <RegisterVerifyCodeWrapper>
          <RegisterVerifyCodeStepHeadingWrapper>
            <RegisterStepTitle>Enter code</RegisterStepTitle>
            <RegisterStepDescription>
              Please check your email, we’ve sent you a 6 digit code
            </RegisterStepDescription>
          </RegisterVerifyCodeStepHeadingWrapper>

          <Spin spinning={loading}>
            <RegisterVerifyCodeBodyWrapper>
              <RegisterVerifyCodeInputWrapper>
                {valueItems.map((digit, idx) => (
                  <RegisterVerifyCodeInput
                    key={idx}
                    type="text"
                    inputMode="numeric"
                    autoComplete="one-time-code"
                    pattern="\d{1}"
                    maxLength={OTP_LENGTH}
                    className="otp-input"
                    value={digit}
                    onChange={e => inputOnChange(e, idx)}
                    onKeyDown={inputOnKeyDown}
                    onFocus={inputOnFocus}
                    autoFocus={idx === 0}
                  />
                ))}
              </RegisterVerifyCodeInputWrapper>

              {!!errorMsg && (
                <TextErrorWrapper>
                  <CenterTextError>{errorText}</CenterTextError>
                </TextErrorWrapper>
              )}

              {Number(minutes) || Number(seconds) ? (
                <RegisterVerifyCodeStepTimer>
                  {minutes}:{seconds}
                </RegisterVerifyCodeStepTimer>
              ) : (
                <RegisterStepBlueLink onClick={handleResendClick}>
                  Resend code
                </RegisterStepBlueLink>
              )}
            </RegisterVerifyCodeBodyWrapper>
          </Spin>
        </RegisterVerifyCodeWrapper>
      </ModalVerifyOtpWrapper>
    </UpdateInformationModal>
  );
};
